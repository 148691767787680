const editedFields = [
    {
        name: 'vn',
        edited: false,
        data: 0,
    },
    {
        name: 'nn',
        edited: false,
        data: 0,
    },
    {
        name: 'em',
        edited: false,
        data: 0,
    },
    {
        name: 'tn',
        edited: false,
        data: 0,
    },
    {
        name: 'st',
        edited: false,
        data: 0,
    },
    {
        name: 'hn',
        edited: false,
        data: 0,
    },
    {
        name: 'plz',
        edited: false,
        data: 0,
    },
    {
        name: 'ort',
        edited: false,
        data: 0,
    },
    {
        name: 'zn',
        edited: false,
        data: 0,
    },
];

window.addEventListener('heyflow-screen-view', (event) => {
    if ((event.detail.screenName = 'msb-vt')) {
        editedFields.forEach((item) => {
            if (item.edited == true) {
                document.querySelector(
                    `[data-variable='${item.name}'][class='variable-replace']`,
                ).innerHTML = item.data;
            }
        });
    }
});

window.addEventListener('heyflow-change', (event) => {

    const fieldsData = event.detail.fields;

    fieldsData.forEach((element) => {
        const wasEdited = element.variable.includes('edited');
        if (wasEdited) {
            const variable = element.variable.split('_edited')[0];
            editedFields.forEach((item) => {
                if (item.name == variable) {
                    item.edited = true;
                    item.data = element.value;
                }
            });
        }
    });
});